import { ComponentType } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, catchError, firstValueFrom, tap } from 'rxjs';
import { CentralServerService } from 'services/central-server.service';
import { DialogService } from 'services/dialog.service';
import { MessageService } from 'services/message.service';
import { SpinnerService } from 'services/spinner.service';
import { ChargingStationsAuthorizations, DialogParamsWithAuth } from 'types/Authorization';
import { BillingInvoice } from 'types/Billing';
import { ChargePointStatus, ChargingStation, Connector, OCPPGeneralResponse } from 'types/ChargingStation';
import { ActionResponse, Paging } from 'types/DataResult';
import { ButtonAction, FilterParams } from 'types/GlobalType';
import { StartTransactionDialogData, Transaction } from 'types/Transaction';
import { User } from 'types/User';
import { Utils } from 'utils/Utils';

import { ChargingStationsStartTransactionQrDialogComponent } from '../charging-station-start-transaction-qr/charging-stations-start-transaction-dialog-qr-component';

@Component({
  selector: 'app-charging',
  templateUrl: 'charging.component.html',
  styleUrls: ['charging.component.scss']
})
export class ChargingComponent implements OnInit, OnDestroy {
  tagId: string;
  userId: string;
  transactionId: number | null = null;
  transactionDetails: Transaction;
  formattedDuration: string;
  energyInKWh: number;
  intervalId: any;
  isTransactionStopped = false;
  chargingStationId: string;
  connectorId: number;
  connector: Connector;
  chargingStation: ChargingStation;
  public connectorStatus: string;
  public connectorExists = true;
  invoiceUrl: string;
  public isLoadingInvoice = true;
  transactoinStarted = false;
  user: User;
  public loading = false;
  isLoading: boolean;
  private token: string;
  private authToken: string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private centralServerService: CentralServerService,
    private spinnerService: SpinnerService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');
    });

    const token = Utils.decodeTokenHeader(this.token);
    const language = localStorage.getItem('language');
    const supportedLanguages = ['en', 'fr', 'es', 'de', 'it', 'pt', 'cs', 'cz'];
    const baseLanguage = language.split('-')[0];
    const languageToUse = supportedLanguages.includes(baseLanguage) ? baseLanguage : 'en';
    this.translateService.use(languageToUse);

    this.authToken = localStorage.getItem('token');
    this.tagId = localStorage.getItem('tagId');
    this.userId = localStorage.getItem('userId');
    const storedTransactionId = localStorage.getItem('transactionId');
    this.transactionId = storedTransactionId ? parseInt(storedTransactionId, 10) : null;
    this.chargingStationId = token.chargingStationID;
    this.connectorId = Number(token.connectorID);
    this.chargingStation = JSON.parse(localStorage.getItem('chargingStation'));
    this.connector = Utils.getConnectorFromID(this.chargingStation, this.connectorId);

    if (this.transactionId) {
      this.getTransactionDetails();
    }

    this.intervalId = setInterval(async () => {
      if (this.transactionId) {
        console.log('ChargingComponent ~ this.intervalId=setInterval ~ this.transactionId:', this.transactionId);
        this.isLoading = true;
        try {
          await this.loadChargingStation(this.chargingStationId, this.connectorId);
          this.getTransactionDetails();
        } catch (error) {
          console.error('Error during interval loading:', error);
        } finally {
          this.isLoading = false;
        }
      } else {
        return null;
      }
    }, 10000);
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  redirectToEv24(): void {
    window.location.href = 'https://www.ev24.io/support/';
  }

  public stopTransaction(dialogService: DialogService, translateService: TranslateService): void {
    dialogService.createAndShowYesNoDialog(
      this.translateService.instant('stop_transaction_title'),
      this.translateService.instant('stop_transaction_confirm', {
        chargeBoxID: this.chargingStation.id,
        transactionId: this.transactionId
      })
    ).subscribe((response) => {
      if (response === ButtonAction.YES) {
        this.spinnerService.show();
        this.centralServerService.stopTransaction(this.transactionId).subscribe({
          next: (res: ActionResponse) => {
            this.spinnerService.hide();
            if (res.status === OCPPGeneralResponse.ACCEPTED) {
              console.log(`Transaction ${this.transactionId} stopped successfully.`);
              if (this.connectorExists) {
                this.isTransactionStopped = true;
                this.messageService.showInfoMessage(
                  this.translateService.instant('chargers.transaction_stopped'),
                  this.translateService.instant('chargers.please_unplug_connector')
                );
                this.waitForConnectorAvailable();
              }
              if (this.intervalId) {
                clearInterval(this.intervalId);
              }
            } else {
              console.error('Failed to stop transaction');
            }
          },
          error: (error) => {
            this.spinnerService.hide();
            console.error('Error stopping transaction:', error);
          }
        });
      }
    });
  }

  public async getUserTransactions(): Promise<number | null> {
    try {
      const userId = localStorage.getItem('userId');
      const filterParams: FilterParams = {
        UserID: userId,
        Issuer: 'true',
        WithCompany: 'true',
        WithSite: 'true',
        WithSiteArea: 'true',
        WithTag: 'true',
        WithUser: 'true',
        WithCar: 'true',
        WithChargingStation: 'true',
        Statistics: 'ongoing',
        Limit: '50',
        SortFields: '-timestamp'
      };
      console.log('ChargingComponent ~ getUserTransactions ~ userId:', userId);
      const transactionResult = await firstValueFrom(this.centralServerService.getActiveTransactions(filterParams));
      console.log('ChargingComponent ~ getUserTransactions ~ filterParams:', filterParams);
      if (transactionResult.result.length > 0) {
        console.log('Transaction ID retrieved:', transactionResult.result[0].id);
        return transactionResult.result[0].id;
      } else {
        console.log('No active transactions found for user');
        return null;
      }
    } catch (err) {
      console.error('Error retrieving transactions:', err);
      return null;
    }
  }

  public getTransactionDetails(): void {
    this.centralServerService.getTransaction(this.transactionId).subscribe({
      next: (transaction) => {
        this.transactionDetails = transaction;
        console.log('ChargingComponent ~ this.centralServerService.getTransaction ~ this.transactionDetails:', this.transactionDetails);
        this.formattedDuration = Utils.convertSecondsToMinutesAndSeconds(transaction.currentTotalDurationSecs);
        this.energyInKWh = Utils.convertWattsToKWh(transaction.currentTotalConsumptionWh);
      },
      error: (error) => {
        console.error('Error fetching transaction details:', error);
      }
    });
  }

  public async startTransaction(): Promise<void> {
    try {
      console.log('Starting transaction...');
      this.spinnerService.show();
      const response = await this.centralServerService.startTransaction(this.chargingStationId, this.connectorId, this.userId, this.tagId).toPromise();
      console.log("ChargingComponent ~ startTransaction ~ response:", response)

      if (response.status === OCPPGeneralResponse.ACCEPTED) {
        console.log('Transaction started successfully');
        await new Promise(resolve => setTimeout(resolve, 3000));
        let retryCount = 0;
        const maxRetries = 15;
        while (retryCount < maxRetries) {
          this.transactionId = await this.getUserTransactions();
          console.log("ChargingComponent ~ startTransaction ~ this.transactionId:", this.transactionId)
          if (this.transactionId) {
            break;
          }
          retryCount++;
          await new Promise(resolve => setTimeout(resolve, 2000));
        }

        if (this.transactionId) {
          const transactionString = this.transactionId.toString();
          localStorage.setItem('transactionId', transactionString);
          this.getTransactionDetails();
        } else {
          console.error('Transaction ID is null or undefined after retries');
        }
      } else {
        console.error('Failed to start transaction');
      }
    } catch (error) {
      console.error('Error starting transaction:', error);
    } finally {
      this.spinnerService.hide();
    }
  }


  public ViewInvoice(): void {
    if (this.invoiceUrl) {
      window.open(this.invoiceUrl, '_blank');
    } else {
      console.log('Invoice URl nit found');
    }
  }

  public async getInvoiceByTransactionID(userID: string, transactionID: number): Promise<boolean> {
    return new Promise((resolve) => {
      this.spinnerService.show();
      console.log('Checking for invoice ~ transactionID:', transactionID);
      const paging: Paging = { limit: 1000, skip: 0 };

      this.centralServerService.getInvoices({ userID }, paging).subscribe({
        next: (invoices) => {
          console.log('Received invoices:', invoices);
          if (invoices && invoices.result.length > 0) {
            let foundInvoice: BillingInvoice | undefined;
            for (const invoice of invoices.result) {
              const session = invoice.sessions.find(s => s.transactionID === transactionID);
              if (session) {
                foundInvoice = invoice;
                console.log('Invoice found:', foundInvoice);
                this.sendInvoiceToUser(foundInvoice);
                resolve(true);
                return;
              }
            }
          }
          console.log('No invoice found for this transaction ID.');
          resolve(false);
        },
        error: (error) => {
          console.error('Error fetching invoice:', error);
          resolve(false);
        }
      });
      this.spinnerService.hide();
    });
  }

  public logout() {
    this.centralServerService.logout().subscribe({
      next: () => {
        this.centralServerService.clearLoginInformation();
      },
      error: (error) => {
        this.centralServerService.clearLoginInformation();
      }
    });
  }

  private getUserByID(userId: string): Observable<User> {
    return this.centralServerService.getUser(userId).pipe(
      tap((user) => {
        this.user = user;
      }),
      catchError((error) => {
        console.error('Error fetching user:', error);
        return EMPTY;
      })
    );
  }

  private waitForConnectorAvailable(): void {
    const statusCheckInterval = setInterval(async () => {
      try {
        await this.loadChargingStation(this.chargingStationId, this.connectorId);

        if (this.connector.status === ChargePointStatus.AVAILABLE) {
          clearInterval(statusCheckInterval);
          console.log('Connector is now available, proceeding with invoice generation');
          this.sendInvoice();
        } else if (this.connector.status === ChargePointStatus.FINISHING) {
          this.messageService.showInfoMessage(
            this.translateService.instant('chargers.transaction_finishing'),
            this.translateService.instant('chargers.please_wait')
          );
        }
      } catch (error) {
        console.error('Error checking connector status:', error);
      }
    }, 5000);

    setTimeout(() => {
      if (statusCheckInterval) {
        clearInterval(statusCheckInterval);
        this.messageService.showWarningMessage(
          this.translateService.instant('chargers.invoice_delay'),
          this.translateService.instant('chargers.contact_support')
        );
      }
    }, 300000);
  }

  private async loadChargingStation(entityId: string, connectorId: number): Promise<void> {
    this.isLoading = true;
    return new Promise<void>((resolve, reject) => {
      this.centralServerService.getChargingStation(entityId).subscribe({
        next: (chargingStation: ChargingStation) => {
          this.chargingStation = chargingStation;
          console.log('ChargingComponent ~ this.centralServerService.getChargingStationQr ~ chargingStation:', chargingStation);

          this.connectorExists = chargingStation.connectors.some((connector) => connector.connectorId === connectorId);
          this.connectorId = connectorId;

          this.connector = Utils.getConnectorFromID(chargingStation, connectorId);

          if (this.connector) {
            const statusKey = `connectorStatus.${this.connector.status.toUpperCase()}`;
            this.translateService.get(statusKey).subscribe((translatedStatus: string) => {
              this.connectorStatus = translatedStatus || this.connector.status;
              this.connectorExists = true;
              this.spinnerService.hide();
              resolve();
            });
          } else {
            this.connectorExists = false;
            this.connectorStatus = '';
            this.spinnerService.hide();
            resolve();
          }
        },
        error: (error) => {
          console.error('Failed to load charging station:', error);
          this.spinnerService.hide();
          this.connectorExists = false;
          this.connectorStatus = '';
          reject(error);
        },
      });
    }).finally(() => {
      this.isLoading = false;
    });
  }

  private copyToClipboard(value: string): void {
    navigator.clipboard.writeText(value).then(() => {
      console.log('Copied to clipboard:', value);
      this.messageService.showInfoMessage(
        this.translateService.instant('transaction_copied', { transactionId: this.transactionId }),
      );
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  }

  private startTransactionForUser(
    chargingStation: ChargingStation,
    connector: Connector,
    userFullName: string,
    userID: string,
    visualTagID: string,
    carID: string | null,
    dialogService: DialogService,
    messageService: MessageService,
    translateService: TranslateService,
    centralServerService: CentralServerService,
    router: Router,
    spinnerService: SpinnerService,
    refresh?: () => Observable<void>
  ): void {
    if (!visualTagID) {
      messageService.showErrorMessage(
        translateService.instant('chargers.start_transaction_missing_active_tag', {
          chargeBoxID: chargingStation.id,
          userName: userFullName,
        })
      );
      return;
    }
    spinnerService.show();
    centralServerService.chargingStationStartTransaction(chargingStation.id, connector.connectorId, userID, visualTagID, carID).subscribe({
      next: async (startTransactionResponse: ActionResponse) => {
        spinnerService.hide();
        if (startTransactionResponse.status === OCPPGeneralResponse.ACCEPTED) {
          await this.getUserTransactions();
          this.checkForTransactionId(this.userId);
          this.transactoinStarted = true;
          messageService.showSuccessMessage(
            translateService.instant('chargers.start_transaction_success', { chargeBoxID: chargingStation.id })
          );
          if (refresh) {
            refresh().subscribe();
          }
        } else {
          Utils.handleError(JSON.stringify(startTransactionResponse),
            messageService, translateService.instant('chargers.start_transaction_error'));
        }
      },
      error: (error) => {
        spinnerService.hide();
        Utils.handleHttpError(error, router, messageService, centralServerService, 'chargers.start_transaction_error');
      }
    });
  }

  private checkForTransactionId(userId: string): void {
    const checkInterval = setInterval(async () => {
      try {
        this.loading = true;
        const transactionId = await this.getUserTransactions();

        if (transactionId) {
          clearInterval(checkInterval);
          localStorage.setItem('transactionId', transactionId.toString());
          this.transactionId = transactionId;
          this.getTransactionDetails();
        }
        this.loading = false;
      } catch (error) {
        console.error('Error checking for transaction ID:', error);
      }
    }, 2000);
  }

  private pollForInvoice(userID: string, transactionID: number): void {
    let retries = 0;
    const maxRetries = 10;
    const pollInterval = 5000;
    this.isLoadingInvoice = true;

    const invoicePolling = setInterval(() => {
      if (retries >= maxRetries) {
        clearInterval(invoicePolling);
        this.isLoadingInvoice = false;
        console.error('Max retries reached. No invoice found.');
        this.spinnerService.hide();
        return;
      }

      this.getInvoiceByTransactionID(userID, transactionID).then((invoiceFound) => {
        if (invoiceFound) {
          clearInterval(invoicePolling);
          this.isLoadingInvoice = false;
          this.spinnerService.hide();
          this.logout();
        }
      });

      retries++;
    }, pollInterval);
  }

  private sendInvoice(): void {
    if (this.connector.status !== ChargePointStatus.AVAILABLE) {
      console.log('Connector not available, skipping invoice generation');
      this.messageService.showWarningMessage(
        this.translateService.instant('chargers.invoice_pending'),
        this.translateService.instant('chargers.please_unplug_first')
      );
      return;
    }
    const checkInterval = setInterval(() => {
      this.loadChargingStation(this.chargingStationId, this.connectorId).then(() => {
        clearInterval(checkInterval);
        this.pollForInvoice(this.transactionDetails.user.id, this.transactionDetails.id);
      }).catch(error => {
        console.error('Error loading charging station:', error);
      });
    }, 5000);
  }

  private sendInvoiceToUser(invoice: any): void {
    this.spinnerService.show();
    this.centralServerService.getInvoice(invoice.id).subscribe({
      next: (invoiceBlob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const invoiceData = JSON.parse(reader.result as string);
          this.invoiceUrl = invoiceData.downloadUrl;
          const userEmail = this.transactionDetails.user.email;
          console.log(`Simulating sending invoice to ${userEmail} with download link: ${this.invoiceUrl}`);
        };
        reader.onerror = (error) => {
          console.error('Error reading the invoice blob:', error);
        };
        reader.readAsText(invoiceBlob);
      },
      error: (error) => {
        console.error('Error fetching invoice:', error);
      }
    });
    this.ViewInvoice();
    localStorage.removeItem('transactionId');
    localStorage.removeItem('tagId');
    localStorage.removeItem('userId');
    localStorage.removeItem('SessionToken');
    localStorage.removeItem('chargingStation');
    localStorage.removeItem('language');
    this.spinnerService.hide();
    this.logout();
  }
}
